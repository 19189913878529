<template>
  <div class="c-suggestion c-suggestion__new" :class="{'c-suggestion_no-botspace': lastBottom}">
    <div class="c-suggestion__title">
      <h2 class="c-suggestion__title-text">You May Also Like?</h2>
    </div>
    <div class="c-suggestion__body">
      <div class="c-suggestion__body-wrapper">
        <div class="c-suggestion__body-list" v-for="(dt,index) in suggestionData" :key="index" @click="openLink(dt.link)">
          <div class="c-suggestion__body-list-box">
            <img class="c-suggestion__body-list-box-img" :src="dt.img">
          </div>
          <p class="c-suggestion__body-list-text">{{dt.text}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lastBottom: Boolean
  },
  data() {
    return {
      suggestionData: []
    }
  },
  mounted() {
    this.getSuggestionData()
  },
  methods: {
    getSuggestionData() {
      let pickData = []
      let hardCodeSuggestion = [
        {
          // 0
          text: "Why choose Sparrow as your trusted digital assets specialist?",
          img: require('@/assets/images/suggestion/suggestion-1@2x.jpg'),
          link: '/about/why-sparrow'
        },
        {
          // 1
          text: "Read more how to trade Sparrow options on the secondary market.",
          img: require('@/assets/images/suggestion/suggestion-2@2x.jpg'),
          link: 'https://blog.sparrowexchange.com/trade-sparrow-options-on-the-secondary-market/'
        },
        {
          // 2
          text: "Achieve your growth objectives with Sparrow’s Bespoke Digital Asset Solutions.",
          img: require('@/assets/images/suggestion/suggestion-3@2x.jpg'),
          link: '/institutional/wealth'
        },
        {
          // 3
          text: "Explore digital asset products tailored to cater to institutions and accredited clients.",
          img: require('@/assets/images/suggestion/suggestion-10@2x.jpg'),
          link: '/institutional/wealth'
        },
        {
          // 4
          text: "Meet Sparrow’s Team of Experts.",
          img: require('@/assets/images/suggestion/suggestion-5@2x.jpg'),
          link: '/about/team'
        },
        {
          // 5
          text: "Join Sparrow and be part of a fast growing and innovative business.",
          img: require('@/assets/images/suggestion/suggestion-4@2x.jpg'),
          link: '/contact-us/join-sparrow'
        },
        {
          // 6
          text: "Sparrow aims to meet the highest compliance and operational standards.",
          img: require('@/assets/images/suggestion/suggestion-6@2x.jpg'),
          link: '/legal/regulation'
        },
        {
          // 7
          text: "Sparrow celebrates major milestones and business achievements.",
          img: require('@/assets/images/suggestion/suggestion-7@2x.jpg'),
          link: '/about/milestone'
        },
        {
          // 8
          text: "Require troubleshooting support in getting started with Sparrow?",
          img: require('@/assets/images/suggestion/suggestion-8@2x.jpg'),
          link: '/support/sparrowcares'
        },
        {
          // 9
          text: "Learn more about the Sparrow business.",
          img: require('@/assets/images/suggestion/suggestion-12@2x.jpg'),
          link: '/about/who-we-are'
        },
      ]

      if (this.routeName == 'whoweare') {
        pickData.push(hardCodeSuggestion[7], hardCodeSuggestion[1], hardCodeSuggestion[2])
      } else if (this.routeName == 'vision&mission') {
        pickData.push(hardCodeSuggestion[3], hardCodeSuggestion[6], hardCodeSuggestion[0])
      } else if (this.routeName == 'corevalues') {
        pickData.push(hardCodeSuggestion[3], hardCodeSuggestion[2], hardCodeSuggestion[0])
      } else if (this.routeName == 'whysparrow') {
        pickData.push(hardCodeSuggestion[3], hardCodeSuggestion[2], hardCodeSuggestion[6])
      } else if (this.routeName == 'meettheteam') {
        pickData.push(hardCodeSuggestion[3], hardCodeSuggestion[2], hardCodeSuggestion[0])
      } else if (this.routeName == 'milestones&achievements') {
        pickData.push(hardCodeSuggestion[3], hardCodeSuggestion[2], hardCodeSuggestion[9])
      } else if (this.routeName == 'personal') {
        pickData.push(hardCodeSuggestion[0], hardCodeSuggestion[6], hardCodeSuggestion[3])
      } else if (this.routeName == 'gettingstarted(institutional)') {
        pickData.push(hardCodeSuggestion[7], hardCodeSuggestion[6], hardCodeSuggestion[0])
      } else if (this.routeName == 'institutions&wealthmanagers' || this.routeName == 'faq') {
        pickData.push(hardCodeSuggestion[0], hardCodeSuggestion[6], hardCodeSuggestion[2])
      } else if (this.routeName == 'news&mediaroom') {
        pickData.push(hardCodeSuggestion[6], hardCodeSuggestion[7], hardCodeSuggestion[0])
      } else if (this.routeName == 'sparrowblog') {
        pickData.push(hardCodeSuggestion[0], hardCodeSuggestion[6], hardCodeSuggestion[2])
      } else if (this.routeName == 'reachouttosparrow') {
        pickData.push(hardCodeSuggestion[6], hardCodeSuggestion[8], hardCodeSuggestion[9])
      } else if (this.routeName == 'bespokewealthmanagement') {
        pickData.push(hardCodeSuggestion[9], hardCodeSuggestion[6], hardCodeSuggestion[7])
      } else if (this.routeName == 'gettingstarted(personal)') {
        pickData.push(hardCodeSuggestion[7], hardCodeSuggestion[6], hardCodeSuggestion[9])
      } else if (this.routeName == 'ourpartners') {
        pickData.push(hardCodeSuggestion[7], hardCodeSuggestion[2], hardCodeSuggestion[9])
      } else if (this.routeName == 'joinsparrow') {
        pickData.push(hardCodeSuggestion[2], hardCodeSuggestion[6], hardCodeSuggestion[0])
      } else {
        pickData.push(hardCodeSuggestion[0], hardCodeSuggestion[1], hardCodeSuggestion[2])
      }

      this.suggestionData = pickData
    }
  },
  computed: {
    routeName() {
      let routeName = this.$route.name.replace(/\s/g, '')
      return routeName.toLowerCase()
    }
  },
  watch: {
    routeName() {
      this.getSuggestionData()
    },
  }
}
</script>
