<template>
  <div class="c-sidebar">
    <div v-if="status == 'about'">
      <a @click='routerRedirect("/about/who-we-are")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Who We Are" }'>Who We Are</a>
      <a @click='routerRedirect("/about/vision")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Vision & Mission" }'>Vision & Mission</a>
      <a @click='routerRedirect("/about/core-values")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Core Values" }'>Core Values</a>
      <!-- <a @click='routerRedirect("/about/team")' class="c-sidebar__link" :class='{"c-sidebar__link_active": routeName =="Meet The Team"}'>Meet The Team</a> -->
      <!-- <a @click='routerRedirect("/about/partners")' class="c-sidebar__link" :class='{"c-sidebar__link_active": routeName =="Our Partners"}'>Our Partners</a> -->
      <a @click='routerRedirect("/about/milestone")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Milestones & Achievements" }'>Milestones & Achievements</a>
      <a @click='routerRedirect("/about/why-sparrow")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Why Sparrow" }'>Why Sparrow</a>
    </div>
    <div v-else-if="status == 'institutional'">
      <!-- <a @click='routerRedirect("/institutional/institution")' class="c-sidebar__link" :class='{"c-sidebar__link_active": routeName =="Institutions & Wealth managers"}'>Institutions & Wealth Managers</a> -->
      <a @click='routerRedirect("/institutional/wealth")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Bespoke Wealth Management" }'>Bespoke Digital Assets</a>
      <a @click='routerRedirect("/institutional/getting-started")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Getting Started (Institutional)" }'>Getting Started</a>
    </div>
    <div v-else-if="status == 'peopleatsparrow'">
      <a @click='routerRedirect("/people-at-sparrow/team")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Meet The Team" }'>Meet The Team</a>
      <a @click='routerRedirect("/people-at-sparrow/join-sparrow")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Join Sparrow" }'>Join Sparrow</a>
    </div>
    <div v-else-if="status == 'consumer'">
      <a @click='routerRedirect("/institutional/wealth")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Bespoke Wealth Management" }'>Institutional</a>
      <a @click='routerRedirect("/consumer/individual")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Personal" }'>Personal</a>
      <a @click='routerRedirect("/institutional/getting-started")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Getting Started (Institutional)" }'>Getting Started</a>
    </div>
    <div v-if="status == 'library'">
      <a @click='routerRedirect("/library/sparrow-blog")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Sparrow Blog" }'>Sparrow Blog</a>
      <a @click='routerRedirect("/library/news-media-room")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "News & Media Room" }'>News & Media Room</a>
      <a @click='routerRedirect("/library/announcements")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Announcements" }'>Announcements</a>
    </div>
    <div v-if="status == 'announcements-detail'">
      <a @click='routerRedirect("/library/announcements")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Announcements" }' @mouseover="backIconHovered = true"
        @mouseleave="backIconHovered = false">
        <img v-if="backIconHovered == false" :src="imgBack" class="c-sidebar__link-icBack" />
        <img v-else-if="backIconHovered == true" :src="imgBackActive" class="c-sidebar__link-icBack" />
        <span class="c-sidebar__link-text">Back</span></a>

    </div>
    <div v-else-if="status == 'support'">
      <a @click='routerRedirect("/support/faq")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "FAQ" }'>FAQ</a>
      <a @click='routerRedirect("/support/sparrowcares")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Sparrow Cares" }'>Sparrow Cares</a>
    </div>
    <div v-else-if="status == 'legal'">
      <a @click='routerRedirect("/legal/disclaimers-and-risk-warning")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Disclaimers & Risk Warning" }'>Disclaimers & Risk Warning</a>
      <a @click='routerRedirect("/legal/regulation")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Regulation" }'>Regulation</a>
      <a @click='routerRedirect("/legal/user-agreement")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "User Agreement" }'>User Agreement</a>
      <a @click='routerRedirect("/legal/privacy-policies")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Privacy Policies" }'>Privacy Policies</a>
    </div>
    <div v-else-if="status == 'joinsparrow'">
      <a @click='routerRedirect("/contact-us/reach-out")' class="c-sidebar__link"
        :class='{ "c-sidebar__link_active": routeName == "Reach Out to Sparrow" }'>Reach Out to Sparrow</a>
      <!-- <a @click='routerRedirect("/contact-us/join-sparrow")' class="c-sidebar__link" :class='{"c-sidebar__link_active": routeName =="Join Sparrow"}'>Join Sparrow</a> -->
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  name: 'Sidebar',
  props: {
    status: String,
    elementClass: {
      type: String,
      default: ".c-getstarted"
    },
  },
  data() {
    return {
      routeName: this.$route.name,
      backIconHovered: false,
      imgBack: require('@/assets/images/ic_arrow_left.svg'),
      imgBackActive: require('@/assets/images/ic_arrow_left_blue.svg'),
    }
  },
  mounted() {
    this.$eventHub.$emit('pass-route-name', this.status);
    if (this.elementClass != "false") {
      this.handleScroll();
    }
  },
  beforeDestroy() {
    $(window).unbind();
  },
  methods: {
    handleScroll: function () {
      let elementClass = this.elementClass
      let sidebarPadding = 50;
      let scrollPosition, sidebarScroll, scrollThreshold, headerHeight;
      $(document).ready(function () {
        $(window).scroll(function () {
          if (!$(elementClass)[0]) {
            return false;
          }
          scrollThreshold = $(elementClass)[0].offsetTop;
          headerHeight = $('.c-header')[0].clientHeight + $('.c-menu')[0].clientHeight
          scrollPosition = window.pageYOffset
          sidebarScroll = scrollThreshold - scrollPosition - sidebarPadding - headerHeight + 10;
          if (sidebarScroll < 0) {
            $('.c-sidebar').css('top', headerHeight + sidebarScroll + 'px')
          }
          else {
            $('.c-sidebar').css('top', headerHeight + 'px')
          }
        })
      })
    },
  }
}
</script>
