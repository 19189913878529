<template>
  <div class="c-getstarted c-getstarted_white">
    <div class="c-getstarted__body-wrapper c-getstarted__body-wrapper_content">
      <div class="c-getstarted__body c-getstarted__body_content">
        <div class="c-getstarted__body-title c-getstarted__body-title_content">
          <p class="c-getstarted__body-title-text">Find out more with Sparrow</p>
          <p class="c-getstarted__body-title-text-description"> Do you need a digital assets strategy? Our team would love
            to help you get ahead of the competition.<span class="c-getstarted__body-title-text-description-arrow"></span>
          </p>
          <button class="c-getstarted__body-title__button" @click="contactUsClick">{{ "Contact Us >" }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  methods: {
    contactUsClick() {
      this.openLink(this.contactUsUrl())
    },
  },
}
</script>
